import React from 'react'

function NewBanner() {
  return (
    <div className='sm:bg-New-Banner bg-Mobile-New-Banner sm:mt-0 mt-[72px] h-[453px] sm:h-[514px] bg-cover sm:px-[97px] px-4  sm:py-[159px] py-[104px] text-white'>
     <h1 className='sm:text-[56px] text-[32px]  sm:font-bold font-semibold  uppercase sm:leading-[72px]'>Your partner in paper manufacturing <br />
     solutions</h1>
     <p className='sm:text-[24px] text-[16px] sm:font-normal sm:mt-3'>Count on us as your partner for innovative, high-quality paper manufacturing solutions across various industries</p>
    </div>
  )
}

export default NewBanner
