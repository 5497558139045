import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { delay, motion } from 'framer-motion'

function Banner() {
  const [bannerImage, setBannerImage] = useState('bg-banner-image');

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerImage(prevBannerImage =>
        prevBannerImage === 'bg-banner-image' ? 'bg-banner-image333' : 'bg-banner-image'
      );
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative sm:w-full h-[725px] w-full overflow-hidden">
    <video src={require("../../Assets/videos/Untitled video - Made with Clipchamp (1) 1.mp4")} className='object-cover w-[100%] h-full' autoPlay loop muted></video>

    <div className="absolute inset-0 flex items-center bg-black bg-opacity-[75%] text-white">
      <div className='text-left sm:ml-[109px] ml-[19px]'>
        <div className="border-0 text-[36px] sm:text-[72px]">
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 0.9, y: 0, transition: { delay: 0.2, duration: 0.9 } }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.9 }}
            viewport={{ once: false, amount: .5 }}
            className='font-light'>Enhancing </motion.span><motion.span
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 0.9, y: 0, transition: { delay: 0.2, duration: 0.9 } }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.9 }}
              viewport={{ once: false, amount: .5 }}
              className='font-semibold'
              >Vibrance</motion.span>
        </div>
        <div className='sm:w-[556px] sm:text-[18px] text-[16px] text-white font-normal tracking-[1px] mt-[8px]'>
        Leading global paper trading company providing sustainable
        paper solutions and efficient services.
        </div>
        <Link to='/aboutdetails'>
          <button className="bg-[#247424] hover:bg-[#309B30]  text-white font-thin sm:w-[183px] sm:h-[56px] py-2 px-4 rounded-md sm:mt-5 mt-4">
            View More
          </button>
        </Link>
      </div>
    </div>
    </div>
  );
}

export default Banner;
