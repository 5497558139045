import React from 'react';
import { motion } from "framer-motion"
function CompanyContact() {
  return (

    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.1, duration: 0.9 }}
      viewport={{ once: false, amount: 0.1 }}
      className="sm:flex justify-between items-center sm:px-24 container sm:h-[400px] h-[479px] mx-auto px-5 py-10 bg-elevate-banner bg-cover   rounded-2xl mt-[110px]">
      <div>
        <h1 className="text-primary-color  mb-4 sm:text-[48px] text-[32px]" style={{ lineHeight: '50px' }}>
          <div className='font-semibold leading-[40px]'>ELEVATE THE WAY YOU SCALE</div>

          <div className='font-light mt-2'>YOUR BUSINESS</div>

        </h1>
        <p className="text-[#FFFFFF] text-[18px] font-normal leading-[26px] mt-[30px] mb-8 sm:mb-0">Get ready to start producing stunning, efficient design <br /> work without the hassles of hiring. Coming soon!</p>
      </div>
      <div>
        <a href='https://mail.google.com/mail/u/0/?hl=en&tf=cm&fs=1&to=info@hudsonpapers.com' target='_blank' className="flex items-center justify-center w-[188px] h-[56px] px-4 rounded-[10px] border-[1px] border-[#FFFFFF] focus:border-white text-center font-medium text-white text-[16px] placeholder-opacity-75 hover:bg-[#309B30] hover:duration-1000 hover:border-none">Contact Now</a>
      </div>
    </motion.div>

  );
}

export default CompanyContact;
