import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';


function Footer() {
  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState('');
  const currentYear = new Date().getFullYear();


  const showSuccessToast = () => {
    Swal.fire({
      icon: 'success',
      title: 'Thank you for subscribing.',
      showConfirmButton: false,
      timer: 2300
    });
  };

  const subscribe = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "db85bad0-62ee-4b96-a378-2a78744fd5cb")

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    })

    const data = await response.json();

    // Validate email format
    const isValidEmail = validateEmail(emailValue);

    if (isValidEmail) {
      // Handle subscription logic
      console.log('Subscribed with email:', emailValue);

      // Clear email input
      setEmailValue('');

      // Show success message
      showSuccessToast();
    } else {
      // Show error message
      setEmailError('Please enter a valid email address.');
    }
  };

  const validateEmail = (email) => {
    // Email validation regex pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  return (


    <footer className=' bg-[#E0EEDF] mt-[120px]'>
      <div className='p-1 sm:mx-[95px] sm:translate-x-[-9px]  '>
        <div className=" mx-20  sm:translate-x-[-27px]">
          <div className="container  mt-[48px] mx-auto px-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6  ml-[-92px]">

              {/* <div className="sm:hidden px-1 w-[267px] rounded-xl translate-y-[-16px] sm:ml-2 translate-x-[-11px] sm:translate-x-[10px]" >
                <h3 className="font-extrabold text-lg   pl-2">Join Our Newsletter</h3>
                <div className=" mt-5 flex pl-[10px] pr-[10px]">
                  <input
                    className='w-[219px] h-[48px] rounded-l-md  text-[#3c3c3c] focus:outline-none bg-white text-[14px] px-3'
                    id='form_input'
                    type="email"
                    value={emailValue}
                    onChange={(e) => {
                      setEmailValue(e.target.value);
                      setEmailError('');
                    }}
                    placeholder='Your email address'
                    style={{ caretColor: 'black' }} // Ensure caret color is set
                  />
                  <button type="submit" onClick={subscribe} className="w-[105px] h-[48px] font-medium rounded-r-md bg-[#247424]  px-4 text-[14px] text-white hover:bg-slate-900">
                    Subscribe
                  </button>
                </div>
                <p className="py-8 pb-6 pl-2 text-xs text-[#3C3C3C]">* Will send you weekly updates for your better tool management.</p>
              </div> */}

              <div className='md:col-span-2 mx-0 sm:ml-16 '>
                <h3 className="font-extrabold text-lg mb-[24px]">Reach us</h3>
                <div className="mb-4 font-normal text-base leading-6 hover:text-[#247424]"><i className="fas fa-phone mr-4 rotate-90"></i><span>+971 55 4646426</span></div>
                <div className="mb-4 font-normal text-base leading-6 hover:text-[#247424]"><i className="fas fa-envelope mr-4"></i><span>info@hudsonpapers.com</span></div>
                <div className='font-normal text-base leading-6 flex hover:text-[#247424]'><i className="fas fa-map-marker-alt mr-4"></i><div><span>GH25+8JV - Al Muqta-Al Muqta, <br></br>1- Emirate of Umm Al Quwain - <br /> United Arab Emirates</span></div></div>
              </div>
              <div className='sm:hidden flex sm:ml-[75px] justify-around gap-14 '>
                <div>
                  <h3 className="font-extrabold text-[18px] mb-[24px]">Company</h3>
                  <ul>
                    <li className="mb-4 font-normal"><Link to='/aboutdetails' className="hover:text-[#247424]">About</Link></li>
                    <li className="mb-4 "><Link to='/contactus' className="font-normal text-base leading-6 hover:text-[#247424]">Contact</Link></li>
                    <li className="mb-4 font-normal"><Link to='/blogs' className="hover:text-[#247424]">Blogs</Link></li>
                  </ul>
                </div>
                <div className='hidden sm:block'>
                  <h3 className="font-extrabold text-lg mb-4">Legal</h3>
                  <ul>
                    <li className="mb-4 font-normal text-[#3C3C3C]"><Link to='/privacypolicy' className="hover:underline hover:text-[#247424]">Privacy Policy</Link></li>
                    <li className="mb-4 font-normal text-[#3C3C3C]"><Link to='/termsandservices' className="hover:underline hover:text-[#247424]">Terms & Services</Link></li>
                  </ul>
                </div>
                <div className='whitespace-nowrap'>
                  <h3 className="font-extrabold text-lg mb-4 whitespace-nowrap">Quick Links</h3>
                  <ul>
                    <li className="mb-4 font-normal"><Link to='/'> Hudson Traders</Link></li>
                    <li className="mb-4 font-normal"><Link to='/products'>Products</Link></li>

                  </ul>
                </div>
              </div>
              <div className='hidden sm:block ml-[75px]'>
                <h3 className="font-extrabold text-lg mb-6">Company</h3>
                <ul>
                  <li className="mb-4 font-normal text-[#3C3C3C] hover:text-[#247424]"><Link to='/aboutdetails'>About</Link></li>
                  <li className="mb-4 font-normal text-[#3C3C3C] hover:text-[#247424]"><Link to='/contactus'>Contact</Link></li>
                  <li className="mb-4 font-normal text-[#3C3C3C] hover:text-[#247424]"><Link to='/blogs'>Blogs</Link></li>
                </ul>
              </div>
              <div className='hidden sm:block ml-[56px]'>
                <h3 className="font-extrabold text-lg mb-6">Legal</h3>
                <ul>
                  <li className="mb-4 font-normal text-[#3C3C3C]"><Link to='/privacypolicy' className="hover:underline hover:text-[#247424]">Privacy Policy</Link></li>
                  <li className="mb-4 font-normal text-[#3C3C3C]"><Link to='/termsandservices' className="hover:underline hover:text-[#247424]">Terms & Services</Link></li>
                </ul>
              </div>
              <div className='hidden sm:block ml-[56px]'>
                <h3 className="font-extrabold text-lg mb-6">Quick Links</h3>
                <ul>
                  <li className="mb-4 font-normal text-[#3C3C3C]"><Link to='/' className="hover:underline hover:text-[#247424]">Hudson Traders</Link></li>
                  <li className="mb-4 font-normal text-[#3C3C3C]"><Link to='/products' className="hover:underline hover:text-[#247424]">Products</Link></li>
                </ul>
              </div>
              <div className="hidden sm:flex flex-col  px-1  w-[267px] rounded-xl  ml-2   translate-x-[10px] sm:translate-x-[10px]" >
                <h3 className="font-extrabold text-lg  pl-2">Join Our Newsletter</h3>
                <form onSubmit={subscribe} className="flex pl-[10px] mt-[24px] mb-[16px] pr-[10px]">
                  <input
                    className='w-[219px] h-[48px] rounded-l-md  text-[#3c3c3c] focus:outline-none  text-[14px] pl-4 '
                    id='form_input'
                    type="email"
                    value={emailValue}
                    onChange={(e) => {
                      setEmailValue(e.target.value);
                      setEmailError('');
                    }}
                    placeholder='Your email address'
                    style={{ caretColor: 'black' }} // Ensure caret color is set
                  />
                  {emailError && <p className="text-red-500 text-xs mt-1 ml-1 absolute bottom-1 left-4">{emailError}</p>}
                  <button type="submit" className="w-[105px] h-[48px] font-medium rounded-r-md bg-[#247424]  px-4 text-[14px] text-white hover:duration-1000  hover:bg-[#309B30]">
                    Subscribe
                  </button>
                </form>
                <p className=" pb-1 pl-2 font-normal text-xs text-[#3C3C3C]">* Will send you weekly updates for your better tool management.</p>
              </div>
              <div className='sm:hidden block'>
                <h3 className="font-extrabold text-lg mb-6">Legal</h3>
                <ul>
                  <li className="mb-4 font-normal text-[#3C3C3C]"><Link to='/privacypolicy' className="hover:underline hover:text-[#247424]">Privacy Policy</Link></li>
                  <li className="mb-4 font-normal text-[#3C3C3C]"><Link to='/termsandservices' className="hover:underline hover:text-[#247424]">Terms & Services</Link></li>
                </ul>
              </div>

            </div>
            <div className="w-auto mt-20   sm:mx-[95px]">
              <div className='border border-[#2474241A] opacity-[50%]'></div>
            </div>
            <div className="mt-8 text-center mb-12 text-[8px] sm:text-[16px]">
              <div><p>&copy; {currentYear} <span className='hover:text-[#247424]'>Hudson Traders</span>. All rights reserved.</p></div>
              <div className="flex justify-center mt-6 ml-[39px]">
                <a href="https://www.facebook.com/profile.php?id=61560821918568" className="mx-2 hover:text-[#247424] text-[20px]"><i className="fab fa-facebook-f"></i></a>
                {/* <a href="https://x.com/i/flow/login" className="mx-2  hover:text-secondary-color text-[20px]"><i class="fab fa-twitter"></i></a> */}
                <a href="https://www.instagram.com/hudsonpapers?igsh=MWVycWNhdTJwMGc5OQ==" className="mx-2  hover:text-[#247424] text-[20px] "><i className="fab fa-instagram"></i></a>
                <a href="#" className="mx-2  hover:text-gray-400 text-[20px]"><i className="fab fa-rss"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
