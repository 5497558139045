import React, { useState, useEffect, useContext, useRef } from 'react';
import logo from '../../Assets/hudsonLogo.png'
import { Link, NavLink } from 'react-router-dom';
import { contextData } from '../../App.js'
import "./Navbar.css"

const Navbar = () => {
  // const [isScrolled, setIsScrolled] = useState(false);

  const {isScrolled,setIsScrolled} = useContext(contextData)

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const divRef = useRef();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  // Check if the page is scrolled
  useEffect(() => {
    const handleScroll = () => {
      // setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav
      className={`fixed w-full h-24 z-10 transition-colors duration-300 ${isScrolled ? 'bg-black bg-opacity-60 backdrop-blur-md shadow-md' : 'bg-transparent'
        }`}
    >
      <div className="container mx-auto sm:px-24 px-4 sm:py-6 py-9 flex justify-between items-center">
        {/* Logo */}
        <div>
          <Link to='/'>
            <img alt='logo' src={logo} className='sm:w-[161px] w-[143px] sm:h-[30px] h-[26px] rounded-lg'></img>
          </Link>
        </div>

        {/* Menu */}
        <ul className="hidden md:flex space-x-[72px]">
          <li>
            <NavLink to='/' className={({ isActive }) => `text-[16px] text-white hover:font-semibold ${isActive ? 'font-bold' : 'font-medium'}`}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to='/aboutdetails' className={({ isActive }) => `text-[16px] text-white hover:font-semibold ${isActive ? 'font-bold' : 'font-medium'}`}>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to='/products' className={({ isActive }) => `text-[16px] text-white hover:font-semibold ${isActive ? 'font-bold' : 'font-medium'}`}>
              Products
            </NavLink>
          </li>
          <li>
            <NavLink to='/blogs' className={({ isActive }) => `text-[16px] text-white hover:font-semibold ${isActive ? 'font-bold' : 'font-medium'}`}>
              Blog
            </NavLink>
          </li>
        </ul>

        <div className="sm:hidden block sm:mr-10 mr-0 cursor-pointer justify-between">
        {!isMenuOpen && (
          <button onClick={toggleMenu} className="text-xl text-white focus:outline-none z-50 ">
            ☰
          </button>
        )}
        {isMenuOpen && (
          <button onClick={toggleMenu} className="text-xl text-white focus:outline-none z-50 ">
            X
          </button>
        )}
        </div>

        {isMenuOpen && (
          <div ref={divRef} className="z-50 flex flex-col space-y-4 md:hidden h-screen bg-white text-[#000000] p-4 absolute top-[6rem] left-0 right-0">
            <Link to='/'  onClick={toggleMenu}>Home</Link>
            <Link to='/aboutdetails'  onClick={toggleMenu}>About Us</Link>
            <Link to='/products'  onClick={toggleMenu}>Products</Link>
            <Link to='/blogs'  onClick={toggleMenu}>Blog</Link>
            <Link to='/contactus'   onClick={toggleMenu}>Contact</Link>
            {/* <a href="#home" className="">Home</a>
              <a href="#about" className="">About</a>
              <a href="#products" className="">Products</a>
              <a href="#blog" className="">Blog</a>
              <a href="#contact" className="">Contact</a> */}
          </div>
        )}

        {/* Button */}
        <div className='sm:block hidden'>
          <Link to='/contactus'>
           {/* <button className="text-white px-4 py-2 rounded-lg w-[144px] h-[48px] text-[12px] border-[1px]">
              Get In Touch
            </button>  */}
           
{/* <button class="button1">Get in touch</button> */}
<button className="button1 border px-4 py-2 rounded-lg w-[144px] h-[48px] text-[12px] font-semibold">
    <div className="state state--default">
        <p className='text-white'>
            <span style={{ '--i': 0 }}>G</span>
            <span style={{ '--i': 1 }}>e</span>
            <span style={{ '--i': 2 }}>t</span>
            <span style={{ '--i': 3 }}>&nbsp;</span> 
            <span style={{ '--i': 4 }}>I</span>
            <span style={{ '--i': 5 }}>n</span>
            <span style={{ '--i': 6 }}>&nbsp;</span> 
            <span style={{ '--i': 7 }}>T</span>
            <span style={{ '--i': 8 }}>o</span>
            <span style={{ '--i': 9 }}>u</span>
            <span style={{ '--i': 10 }}>c</span>
            <span style={{ '--i': 11 }}>h</span>
        </p>
    </div>
</button>



         



          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
