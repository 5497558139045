import React from 'react'
import CompanyInsights from "../../Assets/companyInsight.jpg"
import { Link } from 'react-router-dom'

function CompanyInsight() {
    return (
        <div className='bg-white sm:mb-[120px]'>
            <div className='flex justify-between '>
                <h1 className='sm:text-[48px] text-[32px] font-bold sm:leading-[56px] leading-8 sm:mt-[120px] mt-[72px] '>COMPANY <br />
                    <span className='font-light'>INSIGHTS </span></h1>


                <Link to='aboutdetails'>
                    <button className=' hidden sm:block py-[14px] px-[20px] w-[188px] h-[56px] bg-[#247424] hover:bg-[#309B30] hover:duration-1000 rounded-lg text-white text-[16px] font-semibold sm:mt-[169px] mt-[32px] '>Read More</button>
                </Link>

            </div>
            <div className='sm:mt-12 mt-8 text-justify sm:flex justify-between'>
                <img className='sm:hidden block sm:w-[824px] w-[396px] sm:h-[337px] h-[161px] rounded-lg sm:ml-6' src={CompanyInsights} alt="" />
                <div>
                    <p className=' text-[16px] font-normal sm:leading-8 leading-6 sm:mt-0 mt-8'>
                        We are a solutions-focused company with a deep passion for catering premium printing papers, boards, and    self-adhesives to meet a wide range of needs. </p>

                    <p className='sm:mt-6 mt-6  text-[16px] font-normal sm:leading-8 leading-6'> We take immense pride in offering a range of top-tier products that elevate the printing and packaging industry standards. Our focus isn't just on meeting the immediate needs of our customers but on providing solutions that enhance the overall efficiency and value of their business operations.</p>
                </div>

                <img className='hidden sm:block sm:w-[824px] w-[396px] sm:h-[337px] h-[161px] rounded-lg sm:ml-6' src={CompanyInsights} alt="" />
            </div>

            <Link to='aboutdetails'>
            <button className=' sm:hidden block py-[14px] px-[20px] w-[188px] h-[56px] bg-[#247424] hover:bg-[#309B30] hover:duration-1000 rounded-lg text-white text-[16px] font-semibold sm:mt-[169px] mt-[32px] '>Read More</button>
            </Link>

        </div>
    )
}

export default CompanyInsight
