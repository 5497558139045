import React, { useContext, useEffect } from 'react'
import Banner from '../Banner/Banner'
import AboutUs from '../AboutUs/AboutUs'
import Gallery from '../Gallery/Gallery'
import Brands from '../Brands/Brands'
import CompanyContact from '../CompanyContact/CompanyContact'
import Contact from '../Contact/contact'
import HomeProducts from '../HomeProducts/HomeProducts'
import CompanyInsight from '../CompanyInsight/CompanyInsight'
import NewBanner from '../NewBanner/NewBanner'
import { contextData } from '../../App'

function Home() {

  const {isScrolled,setIsScrolled} = useContext(contextData)

  useEffect (()=> {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
},[])

  return (
    <div>
      <Banner/>
      <div className='px-4 sm:px-20'>
      {/* <AboutUs/> */}
      <CompanyInsight/>
      </div>
      <div>
      <NewBanner/>
      </div>
      <div className='px-4 sm:px-20'>
      <HomeProducts/>
      {/* <Brands/> */}
      <CompanyContact/>
      </div>
      
    </div>
  )
}

export default Home
